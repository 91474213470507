import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import ProtectedRoute from 'components/common/ProtectedRoute';
import DefaultView from 'components/common/DefaultView';

const Main = lazy(() => import(/* webpackPrefetch: true */ 'components/views/main/Main'));
const NotFound = lazy(() => import('components/views/NotFound'));
const Login = lazy(() => import('components/views/Login'));
const Explore = lazy(() => import('components/views/Explore.js'));
const ExploreList = lazy(() => import('components/views/ExploreList.js'));
const ProjectsDetail = lazy(() => import('components/views/ProjectsDetail.js'));
const OpportunitiesDetail = lazy(() => import('components/views/OpportunitiesDetail.js'));
// [hygen] Import views

export const ROUTE_PATHS = {
  LOGIN: '/login',
  DEFAULT: '/',
  NOT_FOUND: '/404',
  EXPLORE: '/explore',
  EXPLORE_LIST: '',
  PROJECTS_DETAIL: 'projects/:id',
  OPPORTUNITIES_DETAIL: 'opportunities/:id',
  // [hygen] Add path routes
};

const routes = [
  {
    path: ROUTE_PATHS.DEFAULT,
    element: (
      <ProtectedRoute>
        <DefaultView>
          <Main />
        </DefaultView>
      </ProtectedRoute>
    ),
    children: [
      { path: '/', element: <Navigate to={ROUTE_PATHS.EXPLORE} /> },
      {
          path: ROUTE_PATHS.EXPLORE,
          element: <Explore />,
          children: [
            { path: ROUTE_PATHS.EXPLORE_LIST, element: <ExploreList /> },
            { path: ROUTE_PATHS.PROJECTS_DETAIL, element: <ProjectsDetail /> },
            { path: ROUTE_PATHS.OPPORTUNITIES_DETAIL, element: <OpportunitiesDetail /> },
          ]
      },
      // [hygen] Add routes
    ],
  },
  { path: ROUTE_PATHS.LOGIN, element: <Login /> },
  {
    path: '*',
    element: (
      <DefaultView>
        <NotFound />
      </DefaultView>
    ),
  },
];

export default routes;
